import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { whiteBoardProducts } from "../../helpers/categoryProducts";

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${tw`w-full h-96 rounded-lg shadow-xl bg-gray-200 flex items-center justify-center`}
`;
const Image = styled.img`
  ${tw`max-w-full max-h-full`}
  object-fit: contain; /* Ensures the image is fully visible within the container */
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`;
const ProductModel = tw.h2`text-2xl font-semibold text-gray-500`;
const ProductDescription = tw.p`text-lg text-gray-600`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const DetailItem = tw.li`py-1`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function WhiteboardProductPage() {
  const { id } = useParams();

  // Find the product by id, using parseInt to convert id to number
  const product = whiteBoardProducts.find((item) => item.id === parseInt(id)) || null;

  // If product is not found
  if (!product) {
    return (
      <>
        <Header />
        <ProductPageContainer>
          <h2>Product not found</h2>
        </ProductPageContainer>
      </>
    );
  }

  return (
    <>
      <Header />

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer>
              <Image
                src={product.imageSrc || "path/to/default-image.jpg"}
                alt={product.name || "Product Image"}
              />
            </ImageContainer>

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>{product.name || "No Product Name Available"}</ProductTitle>
              <ProductModel>Model: {product.model || "N/A"}</ProductModel>
              <ProductDescription>
                {product.description || "No Description Available"}
              </ProductDescription>

              {/* Product Specifications */}
              <SpecificationsTitle>Product Specifications:</SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>Board Type:</strong> {product.boardType || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Core Material:</strong> {product.coreMaterial || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Frame Material:</strong> {product.frameMaterial || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Features:</strong> {product.features || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Sizes:</strong> {product.sizes || "N/A"}
                </DetailItem>
              </ProductSpecifications>

              {/* Additional Details */}
              <SpecificationsTitle>Additional Details:</SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>Colors:</strong> {product.colors?.join(", ") || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Packing:</strong> {product.packing || "N/A"}
                </DetailItem>
              </ProductSpecifications>

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "/contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>

      <Footer />
    </>
  );
}