import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { notebookProducts } from "../../helpers/categoryProducts";

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc || "path/to/default-image.jpg"}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`;
const ProductDescription = tw.p`text-lg text-gray-600 text-center`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function NotebookProductPage() {
  const { id } = useParams();
  const product =
    notebookProducts.find((item) => item.id === parseInt(id)) || null;

  if (!product) {
    return (
      <>
        <Header />
        <ProductPageContainer>
          <h2>Product not found</h2>
        </ProductPageContainer>
      </>
    );
  }

  return (
    <>
      <Header />

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer imageSrc={product.imageSrc} />

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>{product.name || "No Product Name Available"}</ProductTitle>
              <ProductDescription>
                This high-quality notebook is perfect for jotting down notes, ideas, and sketches.
              </ProductDescription>

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "/contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>

      <Footer />
    </>
  );
}