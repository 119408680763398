import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RestaurantLandingPage from "demos/RestaurantLandingPage";
import ContactUs from "pages/ContactUs";
import AboutUs from "pages/AboutUs";
import Catagory from "pages/Catagory";
import Tapes from "components/category/Tapes";
import LDPEProductPage from "components/category/LDPEProductPage";
import ElectronicsProductPage from "components/category/ElectronicsProductPage";
import HDPEProductPage from "components/category/HDPEProductPage";
import LLDPEProductPage from "components/category/LLDPEProductPage";
import PVCProductPage from "components/category/PVCProductPage";
import BlenderProductPage from "components/category/BlenderProductPage";
import CookwareProductPage from "components/category/CookwareProductPage";
import WoodProductPage from "components/category/WoodProductPage";
import WhiteboardProductPage from "components/category/WhiteboardProductPage";
import PencilProductPage from "components/category/PencilProductPage";
import NotebookProductPage from "components/category/NotebookProductPage";
import PaperProductPage from "components/category/PaperProductPage";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          {/* <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/thank-you" element={<ThankYouPage />} /> */}
          <Route path="/" element={<RestaurantLandingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products" element={<Catagory />} />
          <Route path="/products/electronics/:id" element={<ElectronicsProductPage />} />
          <Route path="/products/tapes/:id" element={<Tapes />} />
          <Route path="/products/ldpe/:id" element={<LDPEProductPage />} />
          <Route path="/products/hdpe/:id" element={<HDPEProductPage />} />
          <Route path="/products/lldpe/:id" element={<LLDPEProductPage />} />
          <Route path="/products/pvc/:id" element={<PVCProductPage />} />
          <Route path="/products/wood/:id" element={<WoodProductPage />} />
          <Route path="/products/blender/:id" element={<BlenderProductPage />} />
          <Route path="/products/cookware/:id" element={<CookwareProductPage />} />
          <Route path="/products/whiteboard/:id" element={<WhiteboardProductPage />} />
          <Route path="/products/pencils/:id" element={<PencilProductPage />} />
          <Route path="/products/notebooks/:id" element={<NotebookProductPage />} />
          <Route path="/products/papers/:id" element ={<PaperProductPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
}