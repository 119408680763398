import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { paperProducts } from "./../../helpers/categoryProducts"; // Import paperProducts data

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc || "path/to/default-image.jpg"}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
// const ProductTitle = tw.h1`text-5xl font-extrabold text-gray-800 drop-shadow-lg`;
const ProductTitle = tw.h1`
  text-5xl font-extrabold text-gray-800 drop-shadow-lg 
  overflow-hidden whitespace-nowrap text-ellipsis
`;
const ProductMFR = tw.h2`text-2xl font-semibold text-gray-600 mt-4`;
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-gray-800 mt-4 border-b-2 border-blue-300 pb-2`;
const DetailItem = tw.li`py-1`;
const Button = tw(
    motion.button
  )`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;
  
export default function PaperProductPage() {
  const { id } = useParams();

  // Find the product by id, using parseInt to convert id to number
  const product = paperProducts.find((item) => item.id === parseInt(id)) || null;

  // If product is not found
  if (!product) {
    return (
      <>
        <Header />
        <ProductPageContainer>
          <h2 className="text-2xl text-gray-700 text-center">Product not found</h2>
        </ProductPageContainer>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />

      {/* Main content wrapped with motion.div to add delay */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer imageSrc={product.imageSrc || "path/to/default-image.jpg"} />

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>
                {product.name || "No Product Name Available"}
              </ProductTitle>
              <ProductMFR>MFR: {product.manufacturer || "N/A"}</ProductMFR>

              {/* Specifications */}
              <SpecificationsTitle>Specifications:</SpecificationsTitle>
              <ProductSpecifications>
                {Object.entries(product).map(([key, value]) => (
                  <DetailItem key={key}>
                    <strong>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}:</strong> {value}
                  </DetailItem>
                ))}
              </ProductSpecifications>

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "./../../contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>

      <Footer />
    </>
  );
}